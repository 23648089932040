import Head from "next/head";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export function Favicons() {
  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "reflection-1") {
    return (
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-reflection-1/reflection-1-180x180.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-reflection-1/reflection-1-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-reflection-1/reflection-1-16x16.png`}
        />
        <link
          rel="shortcut icon"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-reflection-1/reflection-1-32x32.png`}
        />
      </Head>
    );
  }

  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "reflection-2") {
    return (
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-reflection-2/reflection-2-180x180.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-reflection-2/reflection-2-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-reflection-2/reflection-2-16x16.png`}
        />
        <link
          rel="shortcut icon"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-reflection-2/reflection-2-32x32.png`}
        />
      </Head>
    );
  }

  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "landing-1") {
    return (
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-1/landing-1-180x180.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-1/landing-1-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-1/landing-1-16x16.png`}
        />
        <link rel="shortcut icon" href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-1/landing-1-32x32.png`} />
      </Head>
    );
  }
  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "landing-2") {
    return (
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-2/landing-1-180x180.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-2/landing-1-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-2/landing-1-16x16.png`}
        />
        <link rel="shortcut icon" href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-2/landing-1-32x32.png`} />
      </Head>
    );
  }
  if (process.env.NEXT_PUBLIC_REACT_APP_SKIN === "landing-3") {
    return (
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-3/landing-1-180x180.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-3/landing-1-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-3/landing-1-16x16.png`}
        />
        <link rel="shortcut icon" href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-3/landing-1-32x32.png`} />
        <link rel="icon" href={`${publicRuntimeConfig.basePath}/icons/V2/V2-landing-3/favicon.ico`} sizes="any" />
      </Head>
    );
  }
  return (
    <Head>
      <link rel="icon" href={`${publicRuntimeConfig.basePath}/favicon.ico`} sizes="any" />
    </Head>
  );
}
